<template>
  <app-module-view>
    <template slot="body">
      <beUser-kpi-filter></beUser-kpi-filter>
      <div class="card">
        <div class="card-body">
          <preloader v-if="callingAPI"></preloader>
          <data-table
            :data="beUsers"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import Preloader from '../../components/preloader/Preloader'
import BeUserKpiFilter from '../../components/filter/FilterBeUserKpi'
import DataTable from '../../components/shared/DataTable'

export default {
  name: 'BeUserKpiList',
  data () {
    return {
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('beUser.id'),
          username: this.$t('beUser.username'),
          department: this.$t('beUser.department'),
          'kpi.articleCnt': this.$t('kpi.kpi.articleCnt'),
          'kpi.articleEditCnt': this.$t('kpi.kpi.articleEditCnt'),
          'kpi.imagesCnt': this.$t('kpi.kpi.imagesCnt'),
          'kpi.viewCnt': this.$t('kpi.kpi.articleView.viewCnt'),
          'kpi.photostoryCnt': this.$t('kpi.kpi.articleType.photostoryCnt'),
          'kpi.quizCnt': this.$t('kpi.kpi.articleType.quizCnt'),
          'kpi.videoCnt': this.$t('kpi.kpi.articleType.videoCnt'),
          'kpi.videoViewCnt': this.$t('kpi.kpi.videoViewCnt')
        },
        actions: {
          edit: 'beUserKpi_edit'
        },
        render: {
          department: departmentId => {
            const dept = this.$store.getters['department/getById'](departmentId)
            if (dept) {
              return dept.name
            }

            return ''
          },
          kpi: kpi => {
            const line = []
            Object.keys(kpi).forEach(key => {
              line.push(key + ':' + kpi[key])
            })

            return line.join(' | ')
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    beUsers () {
      return this.$store.getters['beUserKpi/list']
    },
    totalCount () {
      return this.$store.getters['beUserKpi/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['beUserKpi/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    Preloader: Preloader,
    beUserKpiFilter: BeUserKpiFilter,
    DataTable: DataTable
  },
  methods: {
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('beUserKpi/setPage', page)
      this.$store.dispatch('beUserKpi/fetch')
    }
  },
  mounted () {
    this.page = this.$store.getters['beUserKpi/page']
    this.$store.dispatch('beUserKpi/fetch')
  }
}
</script>

<style lang="scss" scoped>

</style>
